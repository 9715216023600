import { useMemo } from 'react';

import { useCommerce } from '@commerce';
import { SWRHook } from '@commerce/utils/types';
import { useSWRHook } from '@commerce/utils/use-hook';
import { USER_LOGGED_IN_SESSION_STORAGE } from '@framework/const';
import { addQueryString } from '@framework/lib/add-query-string';
import { setStorage } from '@lib/browser-storage';

import type { CustomerHook } from '../types/customer';

export type UseCustomer<H extends SWRHook<CustomerHook<any>> = SWRHook<CustomerHook>> = ReturnType<H['useHook']>;

const handler: SWRHook<CustomerHook> = {
  fetchOptions: {
    url: '/api/customer',
    method: 'GET',
  },
  async fetcher({ input: { locale }, options, fetch }) {
    const url = addQueryString(options.url!, { locale });
    const data = await fetch({ ...options, url: url.pathname + url.search });
    setStorage(USER_LOGGED_IN_SESSION_STORAGE, data?.customer ? 'yes' : 'no');
    return data?.customer ?? null;
  },
  useHook: ({ useData }) =>
    function useCustomHook(input) {
      const response = useData({
        input: [['locale', useCommerce().locale]],
        swrOptions: {
          revalidateOnFocus: false,
          ...input?.swrOptions,
        },
      });

      return useMemo(
        () =>
          Object.create(response, {
            isSubscriber: {
              get() {
                return !!(response.data?.premiumState && response.data?.premiumState > 0);
              },
              enumerable: true,
            },
            hasFreeTrial: {
              get() {
                if (response.data && 'freeTrial' in response.data) {
                  return !!response.data.freeTrial;
                }

                // default to eligible (if not signed in)
                return true;
              },
              enumerable: true,
            },
          }),
        [response]
      );
    },
};

const useCustomer: UseCustomer = (input) => {
  return useSWRHook({ ...handler })(input);
};

export default useCustomer as UseCustomer<typeof handler>;

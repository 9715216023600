export const PASSWORD_PATTERN = '(?=.*?[A-Za-z])(?=.*?[0-9])';

export const PASSWORD_LENGTH = 8;

export const NAME_REGEX_US_CA = /^[a-zA-Z -]+$/;

export const NAME_REGEX = /^[\p{Letter}|\s|-]+$/iu;

export const NAME_MAX_LENGTH = 32;

export const EMAIL_MAX_LENGTH = 70;

export const CITY_MAX_LENGTH = 50;

export const ADDRESS1_MAX_LENGTH = 150;

export const PHONE_NUMBER_REGEX =
  /^\+?([0-9]{1,3}[- ]?)(?:[0-9][- ]?){5,13}[0-9]$|^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/gi;

export const ADDRESS_REGEX = /^[A-Za-z0-9 -]+$/;

export const CITY_REGEX = /^[A-Za-z -]+$/;

export const ALLOWEVERTHING = /.*/;

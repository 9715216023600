// ideally should be slug, but it's not returned by jasper
export enum ProductEntities {
  VALUE_PROPERTIES = 'Value Properties',
  PRODUCT_BADGES = 'Product Badges',
  PRODUCT_DESCRIPTION = 'Featured Product Item',
  PRODUCT_DESCRIPTION_TITLE = 'Product Details Title',
}

export enum ValuePropsSlugs {
  ORDER = 'value-props-order',
  TEXT = 'value-props-text',
}

export enum ProductBadgesSlugs {
  TEXT = 'product-badge-text',
  BG_COLOR = 'product-badge-bg-color',
  TEXT_COLOR = 'product-badge-text-color',
}

export enum ProductDescriptionSlugs {
  HEADER = 'product-description-header',
  CONTENT = 'product-description-content',
  ORDER = 'product-description-order',
}

export enum ProductDescriptionTitleSlugs {
  TITLE = 'product-details-title',
}

export type RoundingType = 'nearest' | 'up' | 'down';

/**
 * round decimal to the specified decimal points
 * `nearest` - rounds to the nearest value
 * `up` - always round up (ceil)
 * `down` - always round down (floor)
 * note: this method will prob break for really big number/decimals
 * which shouldn't be an issue for our use case (2 or 3 decimal tops)
 * if it becomes an issue, use a library dedicated for bigdecimal calculation
 * @param num
 * @param decimalPoints
 * @param roundingType
 * @returns
 */
export default function roundDecimal(num: number, decimalPoints = 2, roundingType: RoundingType = 'nearest'): number {
  const decimals = decimalPoints < 0 ? 0 : decimalPoints;
  switch (roundingType) {
    case 'up': {
      const multiplier = 10 ** decimals;
      return Math.ceil(num * multiplier) / multiplier;
    }
    case 'down': {
      const multiplier = 10 ** decimals;
      return Math.floor(num * multiplier) / multiplier;
    }
    case 'nearest':
    default:
      return Number(num.toFixed(decimals));
  }
}

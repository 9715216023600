export function addQueryString(url: string, queryString: Record<string, unknown>): URL {
  // Use a dummy base as we only care about the relative path
  const udpatedUrl = new URL(url, 'http://a');
  Object.keys(queryString).forEach((key: string) => {
    if (queryString[key]) {
      udpatedUrl.searchParams.set(key, queryString[key] as string);
    }
  });
  return udpatedUrl;
}

import type { FC } from 'react';

import type { BigCommerceImage, BigCommerceImageSize, Image, ResponsiveImage } from '@commerce/types/common';

import { ImageOptions, ImageSet, renderImage, renderResponsiveImage } from './image';

type Props = {
  size: ImageSize;
  image: BigCommerceImage | null;
  options?: ImageOptions;
};

export type ImageSize = keyof BigCommerceImageSize | Partial<Record<keyof ResponsiveImage, keyof BigCommerceImageSize>>;

export const BigCommerceProductImage: FC<Props> = ({ image, options, size }) => {
  if (!image) {
    return null;
  }

  if (typeof size === 'object') {
    const { alt, description } = image;
    const baseImageSet: ImageSet = {
      default: {
        original: { url: image.url, alt, description },
      },
    };
    const imageSet = Object.entries(size).reduce((acc, [breakpoint, imageSize]) => {
      acc.default[breakpoint as keyof ResponsiveImage] = { url: image[imageSize], alt, description };
      return acc;
    }, baseImageSet);
    return renderResponsiveImage(imageSet, options);
  }

  const img: Image = {
    alt: image.alt,
    description: image.description,
    url: image[size],
  };
  return renderImage(img, options);
};

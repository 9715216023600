import tlds from 'tlds';
import { SafeParseReturnType, z } from 'zod';

import { maxDigits, minDigits } from '@lib/validators/digits';
import { EMAIL_MAX_LENGTH, NAME_MAX_LENGTH, PHONE_NUMBER_REGEX } from '@lib/validators/patterns';

import { CheckoutSteps } from './enums/CheckoutSteps';
import { PaymentMethodType } from './enums/PaymentMethodType';
import { checkoutOrder, drCheckoutOrder } from './helpers';

type CheckoutStepValidationResult = { valid: true; error: undefined } | { valid: false; error: string };
type CheckoutStepValidator = (userInfo: any) => CheckoutStepValidationResult;
type AdditionalCheckoutStepValidator = (
  userInfo: any,
  result: CheckoutStepValidationResult
) => CheckoutStepValidationResult;
type CheckoutStepPipelineResult =
  | {
      valid: true;
      failedOnStep: undefined;
      error: undefined;
    }
  | {
      valid: false;
      failedOnStep: CheckoutSteps;
      error: string;
    };

const addressValidation = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  address1: z.string().min(1),
  address2: z.string(),
  city: z.string().min(1),
  stateCode: z.string(),
  zipCode: z.string().min(1),
  country: z.string().min(1),
  phone: z
    .string()
    .regex(PHONE_NUMBER_REGEX)
    .refine(minDigits(7), 'Value must not have less than 7 digits')
    .refine(maxDigits(11), 'Value must not have more than 11 digits'),
});

const customerStepFieldValidation = z.object({
  email: z.string().email(),
});

const shippingStepFieldValidation = z.object({
  shipping: addressValidation,
  consignmentId: z.string().min(1),
});

const deliveryStepFieldValidation = z.object({
  deliveryMethodId: z.string().min(1),
  deliveryMethodName: z.string().min(1),
});

const paymentStepFieldValidation = z.object({
  billing: addressValidation.omit({ phone: true }),
  billingId: z.string().min(1),
  billingAddressType: z.string().min(1),
  paymentMethodType: z.nativeEnum(PaymentMethodType),
  applePay: z.string().nullish(),
  googlePay: z.string().nullish(),
});

const addressStepFieldValidation = z.object({
  email: z.string().email(),
  shipping: addressValidation,
  consignmentId: z.string().min(1),
  billing: addressValidation.omit({ phone: true }),
  billingId: z.string().min(1),
  billingAddressType: z.string().min(1),
});

const paypalFieldValidation = z.object({
  paypal: z.object({
    orderId: z.string().min(1),
    payerId: z.string().min(1),
    email: z.string().email(),
  }),
});

const stripeFieldValidation = z.object({
  stripe: z.object({
    intentId: z.string(),
    customerId: z.string(),
    status: z.string(),
    intentKey: z.string().min(1),
    paymentMethodId: z.string().min(1),
    cardType: z.string().min(1),
    cardExpDate: z.string().regex(/^(1[0-2]|0[1-9])\/[2-9][0-9]{3}$/gm),
    cardLastFour: z.string().regex(/^[0-9]{4}$/gm),
  }),
});

const parseZodResult = (result: SafeParseReturnType<any, any>): CheckoutStepValidationResult => {
  if (result.success) {
    return { valid: true, error: undefined };
  }
  return { valid: false, error: result.error.message };
};

const paymentStepValidation: CheckoutStepValidator = (userInfo) => {
  const res = paymentStepFieldValidation.safeParse(userInfo);

  if (!res.success) {
    return parseZodResult(res);
  }

  switch (res.data.paymentMethodType) {
    case PaymentMethodType.PAYPAL:
      return parseZodResult(paypalFieldValidation.safeParse(userInfo));
    case PaymentMethodType.CREDIT_CARD:
      return parseZodResult(stripeFieldValidation.safeParse(userInfo));
    // TODO apple/google pay
    default:
      return { valid: true, error: undefined };
  }
};

export const checkoutStepValidation: Partial<Record<CheckoutSteps, CheckoutStepValidator>> = {
  [CheckoutSteps.CUSTOMER]: (userInfo) => parseZodResult(customerStepFieldValidation.safeParse(userInfo)),
  [CheckoutSteps.SHIPPING]: (userInfo) => parseZodResult(shippingStepFieldValidation.safeParse(userInfo)),
  [CheckoutSteps.DELIVERY]: (userInfo) => parseZodResult(deliveryStepFieldValidation.safeParse(userInfo)),
  [CheckoutSteps.PAYMENT]: paymentStepValidation,
};

export const drCheckoutStepValidation: Partial<Record<CheckoutSteps, CheckoutStepValidator>> = {
  [CheckoutSteps.ADDRESS]: (userInfo) => parseZodResult(addressStepFieldValidation.safeParse(userInfo)),
  [CheckoutSteps.DELIVERY]: (userInfo) => parseZodResult(deliveryStepFieldValidation.safeParse(userInfo)),
  [CheckoutSteps.PAYMENT]: paymentStepValidation,
};

const getValidationOrderAndRule = (type: 'default' | 'digitalRiver') => {
  if (type === 'digitalRiver') {
    return {
      order: drCheckoutOrder,
      rule: drCheckoutStepValidation,
    };
  }

  return {
    order: checkoutOrder,
    rule: checkoutStepValidation,
  };
};

const additionalValidations: Partial<Record<CheckoutSteps, AdditionalCheckoutStepValidator>> = {
  [CheckoutSteps.SHIPPING]: (userInfo: any, result: CheckoutStepValidationResult) => {
    return userInfo?.shipping?.address1 ? result : { valid: false, error: '' };
  },
};

// TODO to be deleted, not used anymore due removals of "jump to step" feature at checkout
export const validateUpToStep = (
  currentStep: CheckoutSteps,
  userInfo: any,
  isDigitalOnly = false,
  type: 'default' | 'digitalRiver' = 'default'
): CheckoutStepPipelineResult => {
  const { order, rule } = getValidationOrderAndRule(type);
  const currentStepIndex = order.indexOf(currentStep);
  const stepsToValidate = order.slice(0, currentStepIndex); // only validate steps up to current step (exclusive of current step)
  // get the first step that fails
  const failedStep = stepsToValidate
    .map((step) => {
      // skip validating delivery option step for digital only cart
      const validator = isDigitalOnly && step === CheckoutSteps.DELIVERY ? null : rule[step];
      let result = validator ? validator(userInfo) : null;

      if (result) {
        const additionalValidator = additionalValidations[step];
        result = additionalValidator ? additionalValidator(userInfo, result) : result;
      }

      return { result, step };
    })
    .find((item) => item.result && item.result.valid === false);

  if (failedStep?.result?.valid === false) {
    return { valid: false, failedOnStep: failedStep.step, error: failedStep.result.error };
  }

  return { valid: true, failedOnStep: undefined, error: undefined };
};

const maxLength = {
  name: NAME_MAX_LENGTH,
  email: EMAIL_MAX_LENGTH,
};

export const isValidLength = (value: string, type: 'email' | 'name'): boolean =>
  maxLength[type] ? value?.length <= maxLength[type] : false;

const EmailSchema = z
  .string({ invalid_type_error: 'email_invalid', required_error: 'email_invalid' })
  .email({ message: 'email_invalid' });

const NetsuiteEmailSchema = EmailSchema.max(maxLength.email, { message: 'email_too_long' }).refine(
  (v) => {
    const domain = v.split('@')[1] ?? '';
    const splitDomain = domain.split('.');
    const tld = splitDomain[splitDomain.length - 1];
    return !!tld && tlds.includes(tld);
  },
  { message: 'email_invalid' }
);

/**
 * Netsuite has rules for emails
 * - max length
 * - valid TLDs
 * @param email
 * @returns
 */
export const validateNetsuiteEmail = (email: string): { success: true } | { success: false; error: string } => {
  const result = NetsuiteEmailSchema.safeParse(email);

  if (!result.success) {
    return { success: false, error: result.error.errors[0].message };
  }
  return { success: true };
};

/**
 * Sometimes we only need to check email regex or existence.
 * For example, at login.
 */
export const validateEmailFormatOnly = (email: string): { success: true } | { success: false; error: string } => {
  const result = EmailSchema.safeParse(email);
  if (!result.success) {
    return { success: false, error: result.error.errors[0].message };
  }
  return { success: true };
};

export const validateDob = (dob: Date | string | null): boolean => {
  return !Number.isNaN(Date.parse(dob as any));
};

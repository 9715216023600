import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { useTranslation } from 'next-i18next';
import { FocusEventHandler, forwardRef, PropsWithChildren, useMemo } from 'react';

interface LinkProps extends NextLinkProps {
  target?: string;
  title?: string;
  onFocus?: FocusEventHandler;
}

const Link = forwardRef<HTMLAnchorElement, PropsWithChildren<LinkProps>>(
  ({ href, children, prefetch = false, ...props }, ref) => {
    const { t } = useTranslation('common');
    const title = useMemo(
      () => (props.target === '_blank' ? t('common:screenReader.openInNewTab') : undefined),
      [props.target, t]
    );

    return (
      <NextLink href={href} prefetch={prefetch} ref={ref} title={title} {...props}>
        {children}
      </NextLink>
    );
  }
);

Link.displayName = 'Link';

export default Link;

import { i18n } from 'next-i18next.config';

import { LineItem, Upsell } from '@commerce/types/cart';
import { getSubscriptionInfoFromSKU, isL360PremiumSKU, isPremiumSKU } from '@components/cart/helpers';
import { ProductType, SubscriptionType } from '@components/product/enums';
import { isBattery } from '@components/product/helpers';
import { BigcommerceConfig } from '@framework/api';
import { CART_MAX_ITEM } from '@framework/const';
import { CartItemBody } from '@framework/types/cart';

export function exceedsLimit(
  lineItems: (LineItem | CartItemBody)[],
  bundleQuantity: Record<string, number> = {},
  max = CART_MAX_ITEM
): boolean {
  const total = lineItems.reduce((count, { productId, type, quantity }) => {
    if (type === ProductType.PHYSICAL) {
      return count + (quantity || 1) * (bundleQuantity[productId] || 1);
    }
    return count;
  }, 0);

  return total > max;
}

function getSKU(lineItem: LineItem | CartItemBody | Upsell): string {
  if ((lineItem as LineItem).variant?.sku) {
    return (lineItem as LineItem).variant?.sku;
  }
  return (lineItem as CartItemBody).sku || '';
}

export function getSKUs(lineItems: (LineItem | CartItemBody)[]): string[] {
  return lineItems.map(getSKU);
}

export function hasMultiplePremium(
  lineItems: LineItem[],
  addItems: (CartItemBody | Upsell)[],
  config: BigcommerceConfig
): boolean {
  const { locale } = config;
  const totalPremiumAdded = addItems.reduce((acc, { sku }) => acc + (sku && isPremiumSKU(sku, locale) ? 1 : 0), 0);

  // check multiple premium item in the cart (max 1)
  const totalPremiumSKU =
    getSKUs(lineItems).reduce((totalPremium, sku) => totalPremium + (isPremiumSKU(sku, locale) ? 1 : 0), 0) +
    totalPremiumAdded;

  return totalPremiumSKU > 1;
}

export function hasUpsellPremiumOnly(lineItems: LineItem[], locale?: string): boolean {
  if (lineItems.length !== 1) {
    return false;
  }

  const sku = getSKU(lineItems[0]);
  const subscriptionInfo = getSubscriptionInfoFromSKU(sku, locale);

  return !!subscriptionInfo && subscriptionInfo.type === SubscriptionType.UPSELL;
}

/**
 * to ensure that battery is not added together with other non-battery items
 * @param skus
 * @returns boolean
 */
export function hasBatteryWithOtherItems(skus: string[]): boolean {
  const hasBatteryInCart = skus.some((sku) => isBattery(sku));
  const onlyHasBatteryInCart = skus.every((sku) => isBattery(sku));
  return hasBatteryInCart && !onlyHasBatteryInCart;
}

export function canAddLife360Membership(lineItems: (LineItem | CartItemBody)[]): boolean {
  // TEMP: logic for now is if the cart is completely empty
  return lineItems.length === 0;
}

function getLife360Membership(lineItems: (LineItem | CartItemBody)[], locale: string): LineItem | CartItemBody | null {
  // either L360 standalone premium or Jiobit bundle with L360 premium SKU
  return (
    lineItems.find((lineItem) => {
      const itemSku = getSKU(lineItem);
      // handle bundled SKU as well (Jiobit + L360 plan virtual bundle)
      return itemSku.split('_').some((sku) => isL360PremiumSKU(sku, locale));
    }) || null
  );
}

export function hasLife360Membership(lineItems: (LineItem | CartItemBody)[], locale: string): boolean {
  return !!getLife360Membership(lineItems, locale);
}

export function hasL360PremiumAndOtherItem(
  lineItems: (LineItem | CartItemBody)[],
  locale = i18n.defaultLocale
): boolean {
  const l360LineItem = getLife360Membership(lineItems, locale);
  // only 1 L360 premium (or premium bundle with jiobit) per cart
  return hasLife360Membership(lineItems, locale) && (lineItems.length > 1 || (l360LineItem?.quantity || 1) > 1);
}

export function upsellAlreadyInCart(upsellSku: string, lineItems: LineItem[]): boolean {
  return lineItems.some(({ variant: { sku } }) => sku === upsellSku);
}

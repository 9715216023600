export default function insertToArray<T = any>(arr: T[], inserts: { item: T; index: number }[]): T[] {
  let results = [...arr];
  const toInserts = inserts.sort((a, b) => a.index - b.index);
  toInserts.forEach(({ item, index }) => {
    results = insertOne(results, item, index);
  });
  return results;
}

export function insertOne<T = any>(arr: T[], item: T, index: number): T[] {
  return [...arr.slice(0, index), item, ...arr.slice(index, arr.length)];
}

import { Linkable } from '@components/common/Linkable/Linkable';
import { AdCard } from '@components/product/types/Ads';
import { Link } from '@components/ui';
import ImageOrVideo from '@components/ui/ImageOrVideo/ImageOrVideo';

type Props = AdCard;

export const ListingAd = ({ image, ctaText, ctaUrl }: Props) => {
  const adCardContent = (
    <>
      <ImageOrVideo
        className="aspect-square rounded object-cover"
        type="image"
        url={image.url}
        alt={image.alt}
        width="100%"
        enableGifControl={false}
      />
      <Link
        href={ctaUrl}
        className="inline-block my-4 font-normal text-[12px] leading-[14.4px] lg:text-[14.4px] lg:leading-[17.28px]"
      >
        {ctaText} →
      </Link>
    </>
  );

  return (
    <Linkable data-testid="listing-ad-card" className="text-center lg:text-left">
      {adCardContent}
    </Linkable>
  );
};

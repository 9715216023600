import { CSSProperties } from 'react';

import { AdSpacer } from '@components/product/types/Ads';
import { renderImage } from '@lib/image';

import s from './ListingSpacer.module.scss';

type Props = AdSpacer & {
  position: number;
  pageIndex: number;
  pageSize: number;
};

interface SpacerRowCSSProperties extends CSSProperties {
  '--spacer-grid-row-mobile': number;
  '--spacer-grid-row-desktop': number;
}

export const ListingSpacer = ({ title, description, image, position, pageIndex, pageSize }: Props) => {
  const rowsPerPageDesktop = pageSize / 3;
  const rowsPerPageMobile = pageSize / 2;

  const rowMobile = position + rowsPerPageMobile * pageIndex;
  const rowDesktop = position + rowsPerPageDesktop * pageIndex;

  const cssProperties: SpacerRowCSSProperties = {
    '--spacer-grid-row-mobile': rowMobile,
    '--spacer-grid-row-desktop': rowDesktop,
  };

  return (
    <div
      className={s.spacerRow}
      style={cssProperties}
      data-testid="listing-spacer"
      data-listing-spacer-row-mobile={rowMobile}
      data-listing-spacer-row-desktop={rowDesktop}
    >
      <article className="lg:py-4 relative">
        <div className="flex items-center gap-y-4 flex-col lg:block">
          {renderImage(image, {
            className: 'lg:absolute lg:right-0 lg:max-h-full lg:top-0 aspect-[170/70]',
            loading: 'lazy',
            width: 170,
            height: 70,
          })}
          <section className="text-center px-4 pb-4 lg:px-0 lg:pb-0">
            <h4 className="text-[16px] leading-none lg:text-[21.28px] lg:leading-[26.6px] font-bold mb-2 lg:mb-3">
              {title}
            </h4>
            <p className="font-normal text-[12px] lg:text-[16px] text-purple">{description}</p>
          </section>
        </div>
      </article>
    </div>
  );
};

import cn from 'classnames';
import { HTMLAttributes, MouseEventHandler, useRef } from 'react';

type Props<T extends HTMLElement = HTMLElement> = HTMLAttributes<T>;

const ANCHOR_TAG_NAME = 'A';

/**
 * Making the link wrapper linkable, accessibily.
 * Uses click listeners that click the nested link to avoid link nesting.
 */
export const Linkable = ({ children, className, ...props }: Props) => {
  const ref = useRef<HTMLElement>(null);
  const handleClick: MouseEventHandler = (e) => {
    const clickableElems = ref.current?.querySelectorAll('a');
    // only allow click if at most one clickable element
    if (clickableElems?.length === 1) {
      // don't allow click if text is highlighted
      const hasSelection = (window?.getSelection()?.toString().length ?? 0) > 0;
      if (hasSelection) {
        e.preventDefault();
        return;
      }

      // just use default behaviour if clicked element is an anchor tag
      const isAnchorTag = e.target instanceof Element && e.target.tagName === ANCHOR_TAG_NAME;
      if (isAnchorTag) {
        return;
      }

      e.preventDefault();
      clickableElems[0].click();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <article {...props} ref={ref} onClick={handleClick} className={cn('hover:cursor-pointer', className)}>
      {children}
    </article>
  );
};

import cn from 'classnames';
import { FC, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react';

import ImageOrVideoItem from '@components/common/types/ImageOrVideoItem';
import {
  extractImageProvider,
  getContentfulImgSet,
  getContentfulImgUrl,
  renderImage,
  renderResponsiveImage,
} from '@lib/image';
import { renderVideo } from '@lib/video';

const ImageOrVideo: FC<ImageOrVideoItem> = ({
  type = 'image',
  url,
  mobileUrl,
  alt,
  mobileAlt,
  className,
  containerClassName,
  thumbnail = '',
  enableGifControl = true,
  loading = 'eager',
  renderAsThumbnail = false,
  playOnActive = false,
  ...options
}) => {
  const rootClassName = cn('w-full', className);
  const provider = extractImageProvider(mobileUrl || url);
  const [isPlaying, setIsPlaying] = useState(true);
  const togglePlay: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsPlaying((prevState) => !prevState);
  };

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageDataUrl, setImageDataUrl] = useState<string>('');

  const isGif = useMemo(() => type === 'image' && url?.includes('.gif'), [type, url]);

  useEffect(() => {
    const loadImageAndExtractFirstFrame = async (): Promise<void> => {
      const gif = new Image();
      gif.crossOrigin = 'anonymous'; // Set cross-origin attribute to fix Tainted canvases error
      gif.src = url;

      await new Promise((resolve) => {
        gif.onload = resolve;
      });

      const canvas = canvasRef.current;
      if (!canvas) {
        return;
      }
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }
      canvas.width = gif.width;
      canvas.height = gif.height;

      ctx.drawImage(gif, 0, 0, gif.width, gif.height);

      // Convert the canvas content to a data URL
      const dataUrl = canvas.toDataURL('image/png');
      setImageDataUrl(dataUrl);
    };

    if (isGif) {
      loadImageAndExtractFirstFrame();
    }
  }, [url, isGif]);

  if (!url) {
    return null;
  }

  if (type === 'image') {
    if (isGif && enableGifControl) {
      return (
        <div className={cn('items-center flex justify-center relative', containerClassName)}>
          <button
            onClick={togglePlay}
            type="button"
            className="w-full h-full cursor-pointer"
            aria-label="Play/Pause GIF Image"
          >
            {renderImage(
              { url: isPlaying ? url : imageDataUrl, alt },
              { className: `${rootClassName} ${mobileUrl ? 'hidden md:block' : ''}`, loading, ...options }
            )}
            {mobileUrl &&
              renderImage(
                { url: isPlaying ? url : imageDataUrl, alt },
                { className: `${rootClassName} block md:hidden`, loading, ...options }
              )}
          </button>
          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </div>
      );
    }
    if (provider === 'contentful') {
      return renderResponsiveImage(
        getContentfulImgSet({ url, alt }, { url: mobileUrl || url, alt: mobileAlt || alt }),
        {
          className: rootClassName,
          loading,
          ...options,
        }
      );
    }
    return renderImage({ url, alt }, { className: rootClassName, loading, ...options });
  }

  return renderVideo({
    url,
    thumbnail: thumbnail ? getContentfulImgUrl(thumbnail, options.width, 'webp') : undefined,
    type,
    className,
    renderAsThumbnail,
    playOnActive,
  });
};

export default ImageOrVideo;

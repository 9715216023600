export enum ErrorCode {
  INVALID_REQUEST = 'invalid_request',
  INVALID_CREDENTIALS = 'invalid_credentials',
  DUPLICATED_EMAIL = 'duplicated_email',
  NOT_FOUND = 'not_found',
  MISSING_ITEM = 'missing_item',
  UNEXPECTED_ERROR = 'default',
  CART_EXCEEDS_LIMIT = 'cart_exceeds_limit',
  CART_MULTIPLE_PREMIUM = 'cart_multiple_premium',
  PASSWORDS_NOT_MATCH = 'passwords_not_match',
  LIMIT_REACHED = 'limit_reached',
  NO_STOCK = 'no_stock',
  USER_NOT_VERIFIED = 'user_not_verified',
  RESET_TOKEN_EXPIRED = 'reset_token_expired',
  INVALID_TOKEN = 'invalid_token',
  FORBIDDEN = 'forbidden',
  UNVERIFIED_EMAIL = 'unverified_email',
  UNAUTHORIZED = 'unauthorized',
  TILE_PREMIUM_JIOBIT_ADDED = 'tile_premium_jiobit_added',
  JIOBIT_TILE_PREMIUM_ADDED = 'jiobit_tile_premium_added',
  CART_HAS_BATTERY_AND_OTHERS = 'cart_has_battery_and_others',
  CART_HAS_L360_AND_OTHERS = 'cart_has_l360_and_others',
}

import { useCallback } from 'react';

import { useCommerce } from '@commerce';
import { CommerceError } from '@commerce/utils/errors';
import type { MutationHook } from '@commerce/utils/types';
import { useMutationHook } from '@commerce/utils/use-hook';
import { addQueryString } from '@framework/lib/add-query-string';
import { trackEvent, trackGA4ALoginEvent } from '@lib/gtag';

import useCustomer from '../customer/use-customer';
import type { LoginHook } from '../types/login';

const handler: MutationHook<LoginHook> = {
  fetchOptions: {
    url: '/api/login',
    method: 'POST',
  },
  async fetcher({ input: { email, password, code, locale }, options, fetch }) {
    if (!(email && password) && !code) {
      throw new CommerceError({
        message: 'Email and password or authorization code are required to login',
      });
    }

    const url = addQueryString(options.url!, { locale });

    return fetch({
      ...options,
      url: url.pathname + url.search,
      body: { email, password, code },
    });
  },
  useHook: ({ fetch }) =>
    function useCustomHook() {
      const { mutate } = useCustomer();
      const { locale } = useCommerce();
      return useCallback(
        async (actionInput) => {
          const data = await fetch({ input: { ...actionInput, locale } });
          await mutate();
          trackEvent({ action: 'Login', category: 'Auth', label: 'Login' });
          trackGA4ALoginEvent(data?.tsUser?.id ?? '');
          return data;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fetch, mutate]
      );
    },
};

export type UseLogin<H extends MutationHook<LoginHook<any>> = MutationHook<LoginHook>> = ReturnType<H['useHook']>;

const useLogin: UseLogin<typeof handler> = (...args) => {
  return useMutationHook({ ...handler })(...args);
};

export default useLogin;

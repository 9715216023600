import cn from 'classnames';
import { CSSProperties, FC } from 'react';

import s from './LoadingDots.module.css';

interface Props {
  className?: string;
  style?: CSSProperties;
  size?: 'small' | 'medium' | 'large';
}

const LoadingDots: FC<Props> = ({ className, style, size = 'medium' }) => (
  <span className={cn(s.root, s[size], className)} style={style} data-cy="loading-dots">
    <span />
    <span />
    <span />
  </span>
);

export default LoadingDots;

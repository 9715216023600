import cn from 'classnames';

import { ProductImage } from '@commerce/types/product';
import { BigCommerceProductImage } from '@lib/bigcommerce-product-image';

import style from './ProductTile.module.scss';

interface ProductTileProps {
  image: ProductImage | null;
  sku: string;
  onSelect: (sku: string) => void;
  checked: boolean;
}

export default function ProductTile({ image, sku, onSelect, checked }: ProductTileProps) {
  return (
    <button
      role="option"
      aria-selected={checked}
      type="button"
      className={cn(style.option, { [style.selected]: checked })}
      onClick={() => onSelect(sku)}
    >
      <div className={style.display} data-value={sku}>
        {image && <BigCommerceProductImage size="thumbnail" image={image} options={{ className: style.image }} />}
      </div>
    </button>
  );
}
